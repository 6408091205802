import _functionCall from "../internals/function-call";
import _fixRegexpWellKnownSymbolLogic from "../internals/fix-regexp-well-known-symbol-logic";
import _anObject from "../internals/an-object";
import _toLength from "../internals/to-length";
import _toString from "../internals/to-string";
import _requireObjectCoercible from "../internals/require-object-coercible";
import _getMethod from "../internals/get-method";
import _advanceStringIndex from "../internals/advance-string-index";
import _regexpExecAbstract from "../internals/regexp-exec-abstract";
var call = _functionCall;
var fixRegExpWellKnownSymbolLogic = _fixRegexpWellKnownSymbolLogic;
var anObject = _anObject;
var toLength = _toLength;
var toString = _toString;
var requireObjectCoercible = _requireObjectCoercible;
var getMethod = _getMethod;
var advanceStringIndex = _advanceStringIndex;
var regExpExec = _regexpExecAbstract; // @@match logic

fixRegExpWellKnownSymbolLogic("match", function (MATCH, nativeMatch, maybeCallNative) {
  return [// `String.prototype.match` method
  // https://tc39.es/ecma262/#sec-string.prototype.match
  function match(regexp) {
    var O = requireObjectCoercible(this);
    var matcher = regexp == undefined ? undefined : getMethod(regexp, MATCH);
    return matcher ? call(matcher, regexp, O) : new RegExp(regexp)[MATCH](toString(O));
  }, // `RegExp.prototype[@@match]` method
  // https://tc39.es/ecma262/#sec-regexp.prototype-@@match
  function (string) {
    var rx = anObject(this);
    var S = toString(string);
    var res = maybeCallNative(nativeMatch, rx, S);
    if (res.done) return res.value;
    if (!rx.global) return regExpExec(rx, S);
    var fullUnicode = rx.unicode;
    rx.lastIndex = 0;
    var A = [];
    var n = 0;
    var result;

    while ((result = regExpExec(rx, S)) !== null) {
      var matchStr = toString(result[0]);
      A[n] = matchStr;
      if (matchStr === "") rx.lastIndex = advanceStringIndex(S, toLength(rx.lastIndex), fullUnicode);
      n++;
    }

    return n === 0 ? null : A;
  }];
});
export default {};